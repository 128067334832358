<template>
  <section >
    <h2 class="titulo-links-escolha">ADICIONAR TURNO PARA O PROFESSOR</h2>
    <div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="flex md5 sm6 xs12">
                    <va-input
                      :label="$t('Ano')"
                      v-model="professor.ano"
                      disabled
                    />
                  </div>
                  <div class="flex md5 xs12">
                    <va-select
                      :label="$t('Selecione a escola')"
                      v-model="escolaInfo"
                      textBy="nome"
                      track-by="id"
                      :options="escolaALL"
                    />
                  </div>
                  <div class="flex md4 xs12">
                    <va-select
                      :label="$t('Turno')"
                      v-model="horarioSelecionado"
                      textBy="nome"
                      track-by="nome"
                      :options="horarios"
                    />
                  </div>
                  <div class="flex">
                    <va-button class="mr-2 mb-2"  @click="cadastrar"> {{ $t('Cadastrar') }}</va-button>  
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </section >
</template>

<script>

import { Calendario } from "@/class/calendario.js";
import { Escola } from "@/class/escolas.js";
import { Professor } from "@/class/professor.js";
import { Configuracao } from "@/class/configuracao.js";

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  props: {
   id:{
    },
  },
  components: {
   
  },
  name: 'Segmento-Escolar',
  data () {
    return {
        horarios: [],
        professor: {
            nome:null,
            ano:null,
            servidor_id:null,
            escola_id:null,
            turno:null,
            user_id: store_token_info.usuario_logado,
        },
        escolaALL: [],
        escolaInfo:'',
        horarioSelecionado:'',
    }
  },
  methods: {
    async buscarCalendarioAtual() {
      let data = await Calendario.calendarioAtual();
      this.professor.ano = data.data.ano;
    },
     async buscarEscolaALL() {
      let data = await Escola.obtemTodos();
      this.escolaALL = data.data;
    },
    async buscarTurnoALL() {
      let data = await Configuracao.obtemTodos();
      this.horarios = data.data[0].turnos;
    },
    async cadastrar() {
      try {
        this.professor.servidor_id = this.id;
        this.professor.escola_id = this.escolaInfo.id;
        this.professor.turno = this.horarioSelecionado.nome;
        const data = await Professor.addTurnoProfessor(this.professor);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({ name: "servidores" });
      }
      catch(e) {
        alert(e);
        this.$vaToast.init({
          message: "Erro ao cadastrar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'servidores'});
    }
    else {
        this.buscarCalendarioAtual();
        this.buscarEscolaALL();
        this.buscarTurnoALL();
    }
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
